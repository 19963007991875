'use client'
import {useState} from "react";
import ResultRow, {ResultRowSkeleton} from "@/app/components/auctor/result/ResultRow";
import AppIcon from "@/app/components/atomic/AppIcon";

export default function ResultList({templateConfig, appliedFilters, featuredResults, results, isPreview, isHidden}: {
    templateConfig: any,
    appliedFilters: any,
    featuredResults: any[],
    results: any[],
    isPreview?: boolean,
    isHidden?: boolean
}) {
    const [showMore, setShowMore] = useState(false);
    const featuredResultsToShow = showMore ? featuredResults : featuredResults.slice(0, 10);
    const resultsToShow = showMore ? results : results.slice(0, 10);

    return (
        <div
            className={`flex-col items-center w-full gap-4 ${isHidden ? 'hidden' : 'flex'}`}
        >
            {featuredResultsToShow?.map((resultRow: any, idx: number) => (
                <ResultRow
                    isPreview={isPreview}
                    key={'result-' + idx}
                    templateConfig={templateConfig}
                    appliedFilters={appliedFilters}
                    resultRow={resultRow}
                    isFeatured={idx === 0}
                />
            ))}

            {resultsToShow?.map((resultRow: any, idx: number) => (
                <ResultRow
                    isPreview={isPreview}
                    key={'result-' + idx}
                    templateConfig={templateConfig}
                    appliedFilters={appliedFilters}
                    resultRow={resultRow}
                    isFeatured={featuredResults.length === 0 && idx === 0}
                />
            ))}
            {results.length > 10 && (
                <button
                    className="p-2 bg-blue-100 font-semibold border border-blue-300 rounded
                            hover:border-blue-400 hover:bg-blue-400 transition-all duration-300 text-blue-700"
                    onClick={() => setShowMore(!showMore)}
                >
                    {showMore && (
                        <>
                            <AppIcon type="duotone" iconClassName="fa-chevron-up" className="mr-2" />
                            Afficher moins
                        </>
                    )}
                    {!showMore && (
                        <>
                            <AppIcon type="duotone" iconClassName="fa-chevron-down" className="mr-2" />
                            Afficher plus d&apos;offres
                        </>
                    )}
                </button>
            )}
        </div>
    )
}

export function ResultsSkeleton({count}: {
    count: number
}) {
    return (
        <>
            <div className="flex flex-col items-center w-full gap-4">
                {Array.from(Array(count).keys()).map((idx: number) => (
                    <ResultRowSkeleton key={idx} withBanner={idx === 0} />
                ))}
            </div>
        </>
    )
}
