'use client'
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import React, {Suspense, useEffect, useState} from "react";
import ResultBanner, {BannerSkeleton} from "@/app/components/auctor/result/ResultBanner";
import {IBannerConfig, IColumnConfig, IAppliedFilter, ITemplateConfig} from "@/app/models/auctor";
import ResultColumn from "@/app/components/auctor/result/ResultColumn";

import {evaluateExpressionWithFiltersAndRowData} from "@/app/utils/expression-utils";

interface ResultRowProps {
    resultRow: any;
    templateConfig: ITemplateConfig;
    appliedFilters: IAppliedFilter[];
    isPreview?: boolean;
    isFeatured?: boolean;
}

export default function ResultRow({resultRow, templateConfig, appliedFilters, isFeatured = false, isPreview = false}: ResultRowProps) {
    const [enabledBanner, setEnabledBanner] = useState<IBannerConfig | null>(null);
    const [mobileWrappedColumn, setMobileWrappedColumn] = useState<IColumnConfig | null>(null)

    useEffect(() => {
        let bannerToDisplay = structuredClone(getEnabledBannerConfig(resultRow, templateConfig))

        if (isFeatured === true) {
            const featuredText = 'Notre recommandation'
            const featuredIcon = 'fa-medal'
            const featuredColor = '#5cb85c'

            if (!bannerToDisplay) {
                bannerToDisplay = {} as IBannerConfig
            }

            if (bannerToDisplay.content) {
                bannerToDisplay.content = `${featuredText} - ${bannerToDisplay.content}`
            } else {
                bannerToDisplay.content = featuredText
            }

            bannerToDisplay.fa_icon = featuredIcon
            bannerToDisplay.color = featuredColor
        }

        setEnabledBanner(bannerToDisplay as IBannerConfig)
    }, [resultRow, templateConfig, isFeatured])

    const getEnabledBannerConfig = (resultRow: any, templateConfig: any): IBannerConfig | null => {
        let enabledBannerConfig = null
        templateConfig.banner_configs.forEach((bannerConfigFromTemplate: any) => {
            if (!bannerConfigFromTemplate.is_custom_banner && resultRow[bannerConfigFromTemplate.banner_column_name]) {
                enabledBannerConfig = bannerConfigFromTemplate
                enabledBannerConfig.content = resultRow[bannerConfigFromTemplate.banner_column_name]
            }

            if (bannerConfigFromTemplate.is_custom_banner && bannerConfigFromTemplate.enable_condition) {
                try {
                    let expression = bannerConfigFromTemplate.enable_condition
                    if (!expression.includes('return')) {
                        expression = `return ${expression}`
                    }

                    const expressionFunction = new Function('data', expression)
                    const isEnabled = expressionFunction(resultRow)

                    if (isEnabled) {
                        enabledBannerConfig = bannerConfigFromTemplate
                    }
                } catch (error) {
                    console.error(`Error evaluating condition for banner`, error)
                    return null
                }
            }
        })

        return enabledBannerConfig;
    }

    const getColumnCount = (columnConfigs: IColumnConfig[]) => columnConfigs.reduce((acc, col) => acc + col.col_span, 0)

    return (
        <Suspense fallback={<ResultRowSkeleton />}>
            <div className='relative w-full'>
                {enabledBanner &&
                    <Suspense fallback={<BannerSkeleton />}>
                        <ResultBanner
                            banner={enabledBanner}
                            resultRow={resultRow}
                        />
                    </Suspense>
                }
                <div
                    className={`relative grid w-full rounded-xl bg-white z-10 border md:h-50
                        ${enabledBanner ? '-mt-2' : 'border-blue-300'}
                        ${templateConfig?.price_expression ? 'grid-cols-3' : 'grid-cols-1'}
                        md:grid-cols-1`
                    }
                    style={enabledBanner ? {border: `2px solid ${enabledBanner.color}`} : {}}
                >
                    {templateConfig?.price_expression && (
                        <div className="md:hidden flex col-span-1 items-center justify-center p-4 bg-blue-200 border-r border-blue-300 rounded-l-xl">
                            <div
                                className="flex flex-col items-center justify-center gap-1 text-center"
                                dangerouslySetInnerHTML={{
                                    __html: evaluateExpressionWithFiltersAndRowData(
                                        templateConfig.price_expression,
                                        resultRow,
                                        appliedFilters
                                    ) ?? '',
                                }}
                            />
                        </div>
                    )}
                    <div className={`grid md:grid-cols-${getColumnCount(templateConfig.column_configs)} col-span-2 md:col-span-1`}>
                        {templateConfig?.column_configs.map((column: any, i: number) => (
                            <MemoizedResultColumn
                                key={i}
                                isPreview={isPreview}
                                column={column}
                                appliedFilters={appliedFilters}
                                resultRow={resultRow}
                                templateConfig={templateConfig}
                                isWrappableOnMobile={column.wrapped_on_mobile}
                                setMobileWrappedColumn={setMobileWrappedColumn}
                            />
                        ))}
                    </div>

                    {mobileWrappedColumn &&
                        <div className="flex md:hidden col-span-3">
                            <ResultColumn
                                isPreview={isPreview}
                                column={mobileWrappedColumn}
                                appliedFilters={appliedFilters}
                                resultRow={resultRow}
                                templateConfig={templateConfig}
                            />
                        </div>
                    }
                </div>
            </div>
        </Suspense>
    );
}

export function ResultRowSkeleton({withBanner = false}) {
    return (
        <div className={'relative w-full'}>
            {withBanner && <BannerSkeleton />}
            <div
                className={`relative grid bg-blue-100 w-full rounded-xl z-10 border border-blue-300 
                md:h-50 md:grid-cols-4 overflow-hidden ${withBanner && 'mt-9'}`}
            >
                <Skeleton
                    circle
                    inline
                    height={100}
                    width={100}
                    containerClassName="flex-col items-center justify-center p-4 hidden md:flex"
                />

                <Skeleton
                    inline
                    height={80}
                    width={160}
                    borderRadius={'1rem'}
                    containerClassName="flex flex-col items-center justify-center p-4 md:hidden"
                />

                <Skeleton
                    inline
                    width={100}
                    height={20}
                    containerClassName="h-40 md:h-full flex flex-col items-center justify-center p-2 bg-blue-100"
                />

                <Skeleton
                    count={4}
                    height={15}
                    containerClassName="flex-col items-center justify-center p-4 hidden md:flex"
                />

                <Skeleton
                    inline
                    height={32}
                    width={120}
                    containerClassName="flex flex-col items-center justify-center px-4 pb-2 pt-4 md:hidden"
                />

                <Skeleton
                    inline
                    height={50}
                    containerClassName="flex flex-col items-center justify-center px-4 pt-2 pb-4 w-full md:w-40 mx-auto"
                />
            </div>
        </div>
    );
}

const MemoizedResultColumn = React.memo(ResultColumn);
